import React from "react";
import { Link } from "gatsby";

import kebabCase from "lodash/kebabCase";

const Tags = props => {
  // If the current post does not have any tags then stop rendering the component.
  if (!props.listOfTags.length) {
    return null;
  }

  // Display a list of tags for the current blog post.
  return (
    <p>
      {props.listOfTags.map((tag, index) => (
        <button className="squareTagButton tagsBox">
          <Link to={`/tags/${kebabCase(tag)}/`} key={index} className="tag">
         {tag}
          </Link>
        </button>
      ))}
    </p>
  );
};

export default Tags;
