import React from "react"
import Helmet from 'react-helmet';
import { graphql } from "gatsby"
import Layout from "../components/layout"
import rehypeReact from "rehype-react"
import SummaryTable from "../components/summary-table"
import Tags from "../components/tagsList"

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { "summary-table": SummaryTable }
}).Compiler

/*
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />

*/

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { site, markdownRemark } = data // data.markdownRemark holds your post data
  const { siteMetadata } = site
  const { frontmatter, htmlAst } = markdownRemark
  const tags = data.markdownRemark.frontmatter.tags || []
  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.title} | {siteMetadata.title}</title>
        <meta name="description" content={frontmatter.metaDescription} />
      </Helmet>
      <div className="blog-post-container">
        <article className="post">
       
          {!frontmatter.thumbnail && (
            <div className="post-thumbnail">
              <h1 className="post-title" style={{color:"#66578d"}} >test {frontmatter.title}</h1>
              <div className="post-meta">{frontmatter.date}</div>
            </div>
          )}
          {!!frontmatter.thumbnail && (
            <div className="post-thumbnail" style={{backgroundImage: `url(${frontmatter.thumbnail})`}}>
              <h1 className="post-title" style={{/*color:"#E8C298",	*/ position: "relative", 
	top: "120px"}}>{frontmatter.title}</h1>
              <div className="post-meta" style ={{position: "relative", 
	top: "120px"}}>{frontmatter.date}</div>
            </div>
          )}

        <center> <Tags listOfTags={tags}/></center> 
          <div className="blog-post-content">{renderAst(htmlAst)}</div>

        </article>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        thumbnail
        metaDescription
        tags
      }
    }
  }
`